import React from 'react'
import Layout from '../Layout'
import Section from '../Section'
import styled from 'styled-components'
//import Button from '../Button'
//import ButtonWithArrow from '../ButtonWithArrow'
import { Link } from 'gatsby'
import Hero from '../Hero'
import { defaultFormConfig } from '../../../site.config'

const Content = styled.div`
    h1 {
        font-family: 'Roboto', sans-serif;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 600;
        @media (min-width: 801px) {
            && {
                font-size: 5rem;
                margin: 4rem 10% 3rem;
            }
        }
        @media (max-width: 767px) {
            font-size: 2.5rem;
        }
    }
    h2 {
        font-size: 1.8rem;
        margin: 30px 0 10px;
        text-transform: none;
    }
    h1,
    h2 {
        text-align: left;
    }
    h4 {
        font-size: 1.45rem;
        font-weight: 600;
    }
    p {
        text-align: justify;
        padding: 5px 0;
    }
    img {
        margin: 15px 0;
    }
    h1,
    h2,
    p,
    strong,
    li,
    em,
    a {
        font-family: 'Roboto', sans-serif;
    }

    p,
    strong,
    ul li,
    ol li,
    a {
        font-size: 1.2rem;
    }
    ul,
    ol {
        margin: 40px 0;
        padding-left: 2rem;
        li {
            margin: 15px 0;
            font-size: 1.2rem;
            a {
                font-size: 1.2rem;
            }
        }
    }
    ul {
        list-style: disc;
    }
    li {
        margin-bottom: 0.5rem;
    }
    ol {
        list-style: numeric;
    }
    ol ol {
        list-style-type: lower-alpha;
    }
    margin-top: 20px;
    @media (min-width: 801px) {
        margin-top: 60px;
        img {
            margin-bottom: 2rem;
        }
    }
    table {
        margin: 35px auto;
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        th,
        tr:nth-child(1) {
            font-weight: 600;
            p {
                font-weight: 600;
            }
        }
        td,
        th {
            padding: 10px;
            border: solid;
            text-align: left;
            p {
                text-align: left;
                margin-bottom: 0.5rem;
                margin-top: 0;
            }
        }
    }
    @media (max-width: 768px) {
        table {
            td,
            th {
                padding: 5px;
                p,
                strong {
                    font-size: 0.85rem;
                    margin-bottom: 0;
                }
            }
        }
    }
`

const CTA = styled.div`
    margin: 45px auto;
    max-width: 400px;
    @media (max-width: 767px) {
        margin: 15px auto;
    }
`

export default ({ location, description, title, children }) => {
    return (
        <Layout
            location={location}
            title={title}
            description={description}
            noPad={true}
            noPhones={true}>
            {/* <Hero useH1={false} /> */}
            <Hero alternativeImage={true} to="/demande-avocat/" />

            <Section
                paddingTop="0px"
                narrowPadding={true}
                style={{ display: 'block' }}>
                <Content>
                    <h1>{title}</h1>

                    {children}
                    {/*<CTA>
                        <Link to={t('link.toRequest') + '/'}>
                            <ButtonWithArrow>{t('home.find')}</ButtonWithArrow>
                        </Link>
    </CTA> */}
                </Content>
            </Section>
            <Hero />
        </Layout>
    )
}
