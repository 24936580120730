import React from 'react'

import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import arrow from '../../../../static/06-2020/Jurigo-11.svg'

// prettier-ignore
const Container = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    ${({ fullWidth }) => !fullWidth && 
    `@media (max-width: 767px) {
        justify-content: flex-start;
        button {
            font-size: 12px;
            margin: 0;
            width: 92%;
        }
    }`}
    && {
        img {
            margin: ${(props) =>
                !props.fullWidth ? `0 -2.5rem -3rem 0` : `inherit`};
            position: absolute;
            right: 0px;
            width: 30px;
            bottom: 70px;
            z-index: 10;
            @media (max-width: 767px) {
                margin: ${(props) =>
                    !props.fullWidth ? `0px -0.6rem -3rem 0px` : `inherit`};
            }
            button {
                border-radius: 30px;
            }
        }
        
    }
`
const Img = styled.img``

const ButtonWithArrow = ({ onClick, children, fullWidth, disabled }) => (
    <Container fullWidth={fullWidth} className="button-with-arrow">
        <Button
            style={{
                borderRadius: '30px',
                color: 'white',
                fontWeight: 600,
                padding: '12px 25px',
            }}
            type="submit"
            variant="contained"
            color="secondary"
            onClick={onClick}
            disabled={disabled}>
            {children}
        </Button>
        <Img src={arrow} fullWidth={fullWidth} alt="" />
    </Container>
)

export default ButtonWithArrow
